<template>
  <div class="vehicle-list-view">
    <div class="child-page-title">
      <h3>{{ title }}</h3>
    </div>
    <div class="vehicle-list-context">
      <div v-if="hasVal(config)">
        <dv-scroll-board :config="config" :style="'height:' + height + 'px'" />
      </div>
      <div v-else class="empty">暂无报警提示</div>
    </div>
  </div>
</template>
<script>
import { getDeviceAlarmLogList } from '@/api/device'
import { hasVal } from '@/utils/index'
export default {
  name: 'AlarmLogList',
  props: {
    title: {
      type: String,
      default: '实时预报警数据'
    },
    type: {
      type: String,
      default: 'ENVSENSOR'
    },
    rowNum: {
      type: Number,
      default: 4
    },
    height: {
      type: Number,
      default: 250
    }
  },
  data() {
    return {
      top: 50,
      config: null,
      columnWidth: [24],
      aligns: ['right', 'left']
    }
  },
  mounted() {
    this.removeAlarmLogList()
  },
  methods: {
    removeAlarmLogList() {
      this.config = null
      getDeviceAlarmLogList(this.type).then((res) => {
        const { data } = res
        const _data = []
        let _itemData = []
        if (hasVal(data.items) && data.items.length > 0) {
          data.items.forEach((item) => {
            _itemData = []
            _itemData.push(
              "<div style='width:12px;height:12px;border-radius:12px;margin-top: 17px;background:" +
                (item.level === 1 ? '#FF0000' : '#fad400') +
                "'></div>"
            )
            let _context =
              "<div style='max-height:42px;line-height: 22px;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;white-space: normal;overflow: hidden;margin-top:" +
              (item.name.length + item.content.length > 33 ? 0 : 10) +
              "px;'>"
            _context +=
              "<span style='margin-right:5px;color:#dca23f;'>[" +
              item.name +
              ']</span>'
            _context += item.content
            _context += '</div>'
            _context +=
              "<div style='line-height: 22px;float: right;color: #0e8bef;'>" +
              item.time +
              '</div>'
            _itemData.push(_context)
            _data.push(_itemData)
          })
          this.config = {
            columnWidth: this.columnWidth,
            align: this.aligns,
            data: _data,
            rowNum: this.rowNum,
            evenRowBGC: '',
            oddRowBGC: 'rgba(0,255,255,.2)'
          }
        }
      })
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.vehicle-list-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .vehicle-list-context {
    margin-top: 10px;
  }
}
</style>
